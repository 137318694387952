@import "/home/ec2-user/workspace/entschap_veka-app_release_1.33.1/eloket/src/theme/includes.scss";@import "/home/ec2-user/workspace/entschap_veka-app_release_1.33.1/eloket/node_modules/@skryv/core-react-vo/src/theme/includes.scss";
@import "~@govflanders/vl-ui-loader/src/scss/loader";

.dossier-page-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}

.dossier-active-tasks {
  padding-left: 3rem;
}

.vl-modal {
  padding-left: 0;
}
