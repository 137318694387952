@import "/home/ec2-user/workspace/limaatagentschap_veka-app_master/eloket/src/theme/includes.scss";@import "/home/ec2-user/workspace/limaatagentschap_veka-app_master/eloket/node_modules/@skryv/core-react-vo/src/theme/includes.scss";
.veka-loader {
    margin-top: 0;
    width: 100%;
    .vl-region {
      background: #FEF9E5;
      border: 1px solid #E8EBEE;
      padding: 0;
    }
    .vl-loader {
      margin-right: 10px;
    }
    .veka-loader-content {
      display: flex;
      font-size: 18px;
      justify-content: center;
      align-items: center;
      padding: 35px 0;
    }
  }